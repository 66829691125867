import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { propTypes } from '../../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import CustomLinksMenu from './CustomLinksMenu/CustomLinksMenu';
import TopBarLogo from './TopbarLogo.png';
import heartIcon from './heartIcon.svg';
import donateIcon from './donate_icon_vector.png';
import newListing from './newListing.png';

import css from './TopbarDesktop.module.css';

const ProfileMenu = ({
  currentPage,
  currentUser,
  onLogout,
  notificationCount,
  currentUserHasListings,
  authenticatedOnClientSide,
  isAuthenticatedOrJustHydrated,
}) => {
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  if (!authenticatedOnClientSide) {
    if (isAuthenticatedOrJustHydrated) return null;

    return (
      <Menu>
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
          <Avatar className={css.avatar} loggedOut={true} disableProfileLink />
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="SignupPage" style={{ marginBottom: '1rem' }}>
            <NamedLink
              name="SignupPage"
              className={classNames(css.menuLink, currentPageClass('SignupPage'))}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.signup" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="LoginPage">
            <NamedLink name="LoginPage" style={{ textDecoration: 'none' }}>
              <InlineTextButton rootClassName={css.logoutButton}>
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.login" />
              </InlineTextButton>
            </NamedLink>
          </MenuItem>
        </MenuContent>
      </Menu>
    );
  } else {
  }
  return (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="InboxPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.inbox" />
            {notificationDot}
          </NamedLink>
        </MenuItem>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="BulkUploadPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('BulkUploadPage'))}
            name="BulkUploadPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.bulkUploadLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

const TopbarDesktop = props => {
  const {
    className,
    config,
    customLinks,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = config.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const giveSpaceForSearch = customLinks == null || customLinks?.length === 0;
  const classes = classNames(rootClassName || css.root, className);

  // const signupLinkMaybe = isAuthenticatedOrJustHydrated ? null : <SignupLink />;
  // const loginLinkMaybe = isAuthenticatedOrJustHydrated ? null : <LoginLink />;

  return (
    <nav className={classes}>
      <NamedLink name="LandingPage" className={css.logoLink} style={{ textDecoration: 'none' }}>
        <img src={TopBarLogo} style={{ width: '270px' }} />
      </NamedLink>
      <TopbarSearchForm
        className={classNames(css.searchLink, { [css.takeAvailableSpace]: giveSpaceForSearch })}
        desktopInputRoot={css.topbarSearchWithLeftPadding}
        onSubmit={onSearchSubmit}
        initialValues={initialSearchFormValues}
        appConfig={config}
      />

      {/* <CustomLinksMenu
        currentPage={currentPage}
        customLinks={customLinks}
        intl={intl}
        hasClientSideContentReady={authenticatedOnClientSide || !isAuthenticatedOrJustHydrated}
      /> */}
      <NamedLink name="ManageFavouritesPage" className={css.favouriteBase}>
        <img src={heartIcon} />
      </NamedLink>

      <ProfileMenu
        currentPage={currentPage}
        currentUser={currentUser}
        onLogout={onLogout}
        notificationCount={notificationCount}
        currentUserHasListings={currentUserHasListings}
        authenticatedOnClientSide={authenticatedOnClientSide}
        isAuthenticatedOrJustHydrated={isAuthenticatedOrJustHydrated}
      />
      <NamedLink name="NewListingPage" className={css.donateNowButton}>
        <img src={newListing} style={{ width: '100%', marginRight: '20px' }} />
      </NamedLink>
      <a
        className={css.donateNowButton}
        href="https://buy.stripe.com/eVa6ridVY4ZF0Fi000"
        target="_blank"
      >
        <img src={donateIcon} style={{ width: '100%' }} />
      </a>
      {/* {signupLinkMaybe}
      {loginLinkMaybe} */}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  config: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  config: object,
};

export default TopbarDesktop;
